import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LabeledInputField, { RenderSelectField, DateInputField } from '../../utils/custom-fields'
import { useActions } from '../../Action'
import { store } from '../../Store'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import Paginator from 'react-hooks-paginator'
import Paginate from '../pagination'
const moment = require('moment')
import Dropdown from 'react-dropdown'
import datePicker from '../../assets/ic-calendar_default.png'
import seachList from '../../dummyjson/searchExecption.json'
import ExceptionDetails from './exception-details'

const searhException = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const { register, errors, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' })
    let { resources, selectedPage, SearchException, masterdata, Login} = state
    const partnerStoreAgentId = Login && Login.AgentId
    useEffect(() => {
      searchException()
    }, [selectedPage])

    const searchException = (isSearch = false) => {
      if (isSearch) {
        actions.setSelectedPage(0);
      }
      let GetExceptionRequest = {
        pageNumber : isSearch ? 1 : selectedPage + 1,
        pageSize : "10",
        tradeId : SearchException && SearchException.tradeId ? SearchException.tradeId : "",
        startDate : SearchException && SearchException.startDate ? SearchException.startDate : "",
        endDate : SearchException && SearchException.endDate ? SearchException.endDate : "",
        escalationCode : SearchException && SearchException.escalationCode ? SearchException.escalationCode : "",
        agentId : partnerStoreAgentId
      }
      actions.getExceptionDetails({ GetExceptionRequest })
    }

    const escalationCodes = []
    masterdata && masterdata.TradeInEscalationType && masterdata.TradeInEscalationType.map( (data) => {
      escalationCodes.push({
        label: data.TradeInEscalationName,
        value : data.TradeInEscalationName
      })
    })

    const setEscalationCode = (e) => {
      actions.setEscalationCode(e)
    }
    const resetFields = () => {
      actions.setExceptionTradeId(undefined);
      actions.setExceptionStartDate(undefined);
      actions.setExceptionEndDate(undefined);
      actions.setEscalationCode(undefined);
      if (selectedPage == 0) {
        let GetExceptionRequest = {
          pageNumber : "1",
          pageSize : "10",
          tradeId : "",
          startDate : "",
          endDate : "",
          escalationCode : "",
          agentId : partnerStoreAgentId
        }
        actions.getExceptionDetails({ GetExceptionRequest })
      }
      actions.setSelectedPage(0);
      setValue("TradeID", "")
      setValue("StartDate", "")
      setValue("EndDate", "")
      setValue("EscaltionCode", "")
    }
    return (
        <div>
            <div className="ml-50 mr-50 min-h-718 mb-100 font-VodafoneRg">
				<div className="flex items-center pt-30">
                <h2 className="text-32 leading-37 p-1 font-VodafoneRgBd">Exceptions</h2>
                </div>
                <div className="flex w-full items-center">
                    <h2 className="text-17 font-VodafoneRgBd mt-31 ml-4 w-138">Search</h2>
                </div>
              <div className="flex align-items-center">
                <LabeledInputField
                  className="order-no-input search-font"
                  type="text"
                  name="TradeID"
                  schema="logistics"
                  placeholder="EXCEPTIONS_LBL_TRADE_ID"
                  onChange={actions.setExceptionTradeId}
                  register={register}
                  errors={errors}
                  hideLabel
                />
                  {/* <LabeledInputField
                    className="date-input search-font"
                    type="date"
                    register={register}
                    errors={errors}
                    placeholder="EXCEPTIONS_LBL_START_DATE"
                    onChange={actions.setExceptionStartDate}
                    name="StartDate"
                    schema="logistics"
                    hideLabel
                   value={SearchException ? SearchException.startDate:''}
                  /> */}
                  <DateInputField 
                    onChange={actions.setExceptionStartDate}
                    placeholder = "EXCEPTIONS_LBL_START_DATE"
                    value={SearchException && SearchException.startDate ? SearchException.startDate:''}
                    format="dd-MM-yyyy"
                  />
                  {/* <LabeledInputField
                    className="date-input search-font"
                    type="date"
                    register={register}
                    errors={errors}
                    placeholder="EXCEPTIONS_LBL_END_DATE"
                    onChange={actions.setExceptionEndDate}
                    name="EndDate"
                    schema="logistics"
                    hideLabel
                    value={SearchException ? SearchException.endDate:''}
                  /> */}
                  <DateInputField 
                    onChange={actions.setExceptionEndDate}
                    placeholder = "EXCEPTIONS_LBL_END_DATE"
                    value={SearchException && SearchException.endDate ? SearchException.endDate:''}
                    format="dd-MM-yyyy"
                  />
                {/* <LabeledInputField
                  className="order-no-input search-font"
                  type="text"
                  placeholder="Make"
                  name="EscaltionCode"
                  schema="logistics"
                  placeholder="EXCEPTIONS_LBL_ESCALATION_CODE"
                  onChange={actions.setEscalationCode}
                  register={register}
                  errors={errors}
                  hideLabel
                /> */}
                <RenderSelectField
                  name="exceptionList"
                  label=""
                  className="select_box_exceptions"
                  register={register}
                  onChange={(e) => setEscalationCode(e)}
                  options={escalationCodes}
                  optionsKey="value"
                  optionsValue="label"
                  errors={errors}
                  schema="logistics"
                  value={SearchException ? SearchException.escalationCode:''}
                />

                <button className="purple-button w-80 h-8 search-font" onClick={() => searchException(true)}>
                  {resources.VODAFONE_SEARCH_BUTTON}
                </button>
                <button className="black-button w-80 h-8 search-font ml-10" onClick={() => resetFields()}>
                  {resources.VODAFONE_RESET_BUTTON}
                </button>
            </div>
           { seachList && seachList.exceptionList.length > 0 ?
                <ExceptionDetails/> : null
            } 
            </div>
        </div>
    )
}

export default searhException