import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
//import { useForm } from 'react-hook-form'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom'
import { store } from '../Store'
import vodaLogo from '../assets/img-vodafone-1.png'
import vodaBanner from '../assets/Banner.png'
import LabeledInputField from '../utils/custom-fields'
import tooltipIcon from '../assets/ic-help_default.png'
import img_asurion from '../assets/img-asurion.png'
import { useActions } from '../Action'
import Select from 'react-select'

const moment = require('moment')

const Header = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const history = useHistory()
  const {
    storeName, masterdata, TradeInfoSearched, resources, Login, LogisticsTab,
    PartnerStoreCodeNbr, partnerStoreName, roles, workingAs, disableStoreSelection, ExceptionsTab,OrderTab,ReportsTab
  } = state
  const { PartnerStoreCode, PartnerParentStoreCode, StoreAgentRole } = Login
  const stores = masterdata && masterdata.Stores
  const { register, errors, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      PartnerStoreCodeNbr: state && state.PartnerStoreCodeNbr
    },
  })

  let defaultPartnerStoreCode = PartnerStoreCode;
  const tabRoles = roles && roles.filter(r => r.menu === 'Header' && r.section === 'Tabs') &&
    roles && roles.filter(r => r.menu === 'Header' && r.section === 'Tabs')[0];
  const storeRoles = roles && roles.filter(r => r.menu === 'Header' && r.section === 'StoreSelection') &&
    roles && roles.filter(r => r.menu === 'Header' && r.section === 'StoreSelection')[0];

  // const deviceOptions = []
  // stores &&
  //   stores.map((d) => {
  //     deviceOptions.push({
  //       label: d.PartnerStoreName,
  //       value: d.PartnerStoreId,
  //     })
  //   })
  let show = document.querySelectorAll('.show');
  let headertooltipid = show && show[0] && show[0].id
  let tooltipActive = show && (headertooltipid === 'header-tooltip') ? true : false
  // const showStoreName = (StoreAgentRole === "Store" || StoreAgentRole === 'Store Manager') ? (workingAs ? true : false) : true
  const [currentDate, setCurrentDate] = useState(moment().format('D MMMM YYYY h:mm:ss'));
  function displayTime() {
    setInterval(() => {
      setCurrentDate(moment().format('D MMMM YYYY h:mm:ss'))
    }, 1000)
  }

  const logoutFunc = () => {
    window.location.replace('/')
  }

  const logisticsClick = () => {
    actions.setOrderTab(false)
    actions.setLogisticsTab(true)
    actions.setReportingTab(false)
    actions.setExceptionTab(false)
    history.replace('/logistics')
  }

  const reportingClick = () => {
    actions.setReportSummaryTab(true)
    actions.setReportDetailsTab(false)
    actions.setReportCareCreditTab(false)
    actions.setOrderTab(false)
    actions.setReportingTab(true)
    actions.setLogisticsTab(false)
    actions.setExceptionTab(false)
    history.replace('/reports')
  }

  const orderClick = () => {
    actions.setOrderTab(true)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setExceptionTab(false)
    history.replace('/orderSearch')
  }

  const exceptionClick = () => {
    actions.setOrderTab(false)
    actions.setLogisticsTab(false)
    actions.setReportingTab(false)
    actions.setExceptionTab(true)
    history.replace('/exception')
  }

  const setPartnerStoreCode = (value) => {
    actions.setPartnerStoreCode(value)
    let filteredStore = stores && stores.filter((s) => s.PartnerStoreCode === value && s.PartnerParentStore === PartnerParentStoreCode)
    let storeNameData = filteredStore && filteredStore[0] && filteredStore[0].PartnerStoreName
    actions.setPartnerStoreName(storeNameData)
  }

  const checkStoreCode = (value) => {
    if (!value) {
      setValue("PartnerStoreCodeNbr", defaultPartnerStoreCode);
      setPartnerStoreCode(defaultPartnerStoreCode);
    }
  }
  // const setWorkingAs = (value) => {
  //   actions.setWorkingAs(value)
  //   if (value === 'Care') {
  //     actions.setPartnerStoreCode('VODACARE')
  //     let filteredStore = stores && stores.filter((s) => s.PartnerStoreCode === 'VODACARE')
  //     let storeNameData = filteredStore && filteredStore[0] && filteredStore[0].PartnerStoreName
  //     actions.setPartnerStoreName(storeNameData)
  //     actions.setStoreAgentRole('Care')
  //   }
  //   else {
  //     setPartnerStoreCode(Login && Login.PartnerStoreCode)
  //   }
  // }
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase();
  return (
    <div>
      <div className="bg-red-1 px-50">
        <img className="py-14 inline-block mr-70" src={vodaLogo} alt="asurion-img" />
        <ul className="list-none inline-block">
          {tabRoles['Order'][agentRole] === 'SHOW' &&
            <li className={`${OrderTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-60 text-17 leading-20 text-white mr-20 cursor-pointer`}>
              <a onClick={() => { orderClick() }}>{resources.VODAFONE_HEADER_LBL_ORDER}</a>
            </li>
          }
          {tabRoles['Logistics'][agentRole] === 'SHOW' &&
            <li className={`${LogisticsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-white mr-20 cursor-pointer`}>
              <a onClick={() => { logisticsClick() }}>{resources.VODAFONE_HEADER_LBL_LOGISTICES}</a>
            </li>
          }
          {tabRoles['Reporting'][agentRole] === 'SHOW' &&
            <li className={`${ReportsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-white mr-20 cursor-pointer`}>
              <a onClick={() => { reportingClick() }}>{resources.VODAFONE_HEADER_LBL_REPORTING}</a>
            </li>
          }
          {tabRoles['Exceptions'][agentRole] === 'SHOW' &&
          <li className={`${ExceptionsTab ? 'font-semibold' : ' '} inline-block font-VodafoneRg w-75 text-17 leading-20 text-white mr-20 cursor-pointer`}>
              <a onClick={() => { exceptionClick() }}>{resources.VODAFONE_HEADER_LBL_EXCEPTION}</a>
            </li>
          }
        </ul>
        <div className="sm:float-right flex flex-col">
          <p className="mt-10 text-sm text-white leading-16 self-end">{currentDate}</p>
          {displayTime()}
          <div>

            <span className="text-17 text-white leading-28 mr-15">{Login && Login.StoreAgentName}</span>
            < span className="inline-block mr-15 text-white" >
              <img src={tooltipIcon} className={`${tooltipActive ? 'image-click ' : ''} image-hover cursor-pointer inline-block `} data-tip data-for="header-tooltip" data-event="click focus"></img>
              <ReactTooltip delayHide={0} delayShow={1} globalEventOff="click" place="left" id="header-tooltip" className={`header-tooltip`} type="error" clickable arrowColor="transparent">
                <div className="text-black text-15 leading-18 font-VodafoneRg">
                  <p className="">
                    For store related queries, please reach out to
                                <br />
                    <a className="text-purple-400" href="mailto:Vodafone-store_enquiries@asurion.com">
                      Vodafone-store_enquiries@asurion.com
                </a></p>
                  <div className="mt-10">
                    <img className="float-right inline-block" src={img_asurion} alt="asurion-img" />
                  </div>
                </div>
              </ReactTooltip>
            </span >
            <span className="text-17 text-white leading-28">|</span>
            <span className="text-17 text-white leading-28 ml-15 cursor-pointer" onClick={() => logoutFunc()}>
              {resources.VODAFONE_HEADER_LBL_LOGOUT}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-gray-5 px-50 store-code-container">

        <div className="sm:float-right flex flex-col store-code-margin">
          <div>
            <span className="mr-10 store-code-text">
              {partnerStoreName}
            </span>
            <span>
              <LabeledInputField
                name="PartnerStoreCodeNbr"
                type="text"
                label=""
                disabled={TradeInfoSearched || storeRoles['StoreLocation'][agentRole] !== 'SHOW'}
                placeholder=""
                className={`${TradeInfoSearched || (storeRoles['StoreLocation'][agentRole] !== 'SHOW') ? 'cursor-not-allowed bg-white ' : ''} store-code-input store-code-text h-30`}
                onChange={(e) => setPartnerStoreCode(e)}
                register={register}
                errors={errors}
                schema="verification"
                onBlur={(e) => checkStoreCode(e)}
                enableBlurEvent={true}
                value={PartnerStoreCodeNbr}
              />
            </span>
          </div>
        </div>

        {/* {(StoreAgentRole === "Store" || StoreAgentRole === 'Store Manager') &&
          <div className="sm:float-right flex flex-row store-code-margin">
            <div className="mr-10">
              <label class="radio-container">Store
              <input type="radio" id="store" name="workingAs"
                  checked={workingAs == 'Store'} onChange={() => setWorkingAs('Store')}
                  disabled={disableStoreSelection} />
                <span class={`radiomark ${disableStoreSelection ? ' opacity-25 cursor-not-allowed' : ''}`}></span>
              </label>
            </div>
            <div className="mr-10">
              <label class="radio-container">Care
              <input type="radio" id="care" name="workingAs"
                  checked={workingAs == 'Care'} onChange={() => setWorkingAs('Care')}
                  disabled={disableStoreSelection} />
                <span class={`radiomark ${disableStoreSelection ? ' opacity-25 cursor-not-allowed' : ''}`}></span>
              </label>
            </div>
          </div>
        } */}
      </div>
    </div>
  )
}
export default Header
