import React, { useContext } from 'react'
import LabeledInputField from '../utils/custom-fields'
//import { useForm } from 'react-hook-form'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { useActions } from '../Action'
import { store } from '../Store'
import { useHistory } from 'react-router-dom'

import vodaLogo from '../assets/img-vodafone.png'
import vodaBanner from '../assets/Banner.png'

const forgotPassword = () => {
    const { state, dispatch } = useContext(store)
    const actions = useActions(state, dispatch)
    const history = useHistory()
    const { register, errors, formState } = useForm({
        mode: 'onChange',
        defaultValues: {},
    })
    const { isValid } = formState
    const { Login, isForgotPswdClicked, isForgotPassFailure, forgotPasswordSuccessResponse, forgotPasswordError } = state
    const status = forgotPasswordSuccessResponse && forgotPasswordSuccessResponse.Status
    const { emailId } = Login
    const resources = state && state.resources
    const forgotError = forgotPasswordError && forgotPasswordError.Error
    const forgotErrorStatus = forgotPasswordError && forgotPasswordError.Status
    if (status && status === 'Success') {
        history.replace('/changepass')
    }
    let ErrorMessage = ''
    const forgotPassword = () => {
        actions.forgotPasswordRequest({
            username: emailId
        })
    }
    return (
        <div>
            <div className="">
                <img src={vodaLogo} className="mt-50 mb-30 ml-50" />
            </div>
            <div className="">
                <img src={vodaBanner} className="" />
            </div>
            <div className="mx-auto max-w-screen-lg mb-30">
                <div className="flex flex-row mt-80 justify-center">
                    <div className="flex flex-col">
                        <div className="inline-block mt-20 login-container">
                            <LabeledInputField
                                name="username"
                                type="text"
                                labelClass="w-130 inline-block"
                                errorClass="text-center"
                                label="VODAFONE_AGENT_ID_LABEL"
                                placeholder="VODAFONE_ID_PLACEHOLDER"
                                className="mt-10 w-300 h-50"
                                onChange={actions.setEmailId}
                                register={register}
                                errors={errors}
                                schema="verification"
                                defaultValue={Login && Login.emailId}
                            />
                            {isForgotPassFailure ? <div className="error-text pl-130 ">{forgotErrorStatus === 'failure' ? forgotError : forgotErrorStatus === 'account_lock' ? "Too many failed attempts. Your account has been locked, please try again in 10 minutes." : "" }</div> : null}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-block mt-30 ml-17">
                            <button
                                disabled={!isValid}
                                onClick={() => forgotPassword()}
                                type="submit"
                                className={`${!isValid ? 'disable-button' : 'purple-button'} btn`}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default forgotPassword