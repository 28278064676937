import React, { useContext, useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom'
//import { useForm } from 'react-hook-form'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import ReactTooltip from 'react-tooltip'
import { store } from '../../Store'
import { useActions } from '../../Action'
import LabeledInputField from '../../utils/custom-fields'
import OrderDetails from './order-details'
import logo from '../../assets/ic-info_default.png'
import logo1 from '../../assets/ic-info_active.png'

const OrderSearch = () => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const {
    TradeInfoSearched, isError, TradeNotFound, TradeInfoError, storeId, orderType,workingAs,
    customerTradeId, resources, ErrorDetails, partnerStoreName, TradeInfo, resetCustomerTradeIdField
  } = state
  const TradeInStatus = TradeInfo && TradeInfo.TradeInStatus

  const { AccessToken, IdToken, RefreshToken ,StoreAgentRole} = state.Login
  // let tokenHeders = { 'AccessToken': AccessToken, 'IdToken': IdToken, 'RefreshToken': RefreshToken }
  //const errorMessage = ErrorDetails && ErrorDetails.response && ErrorDetails.response.data && ErrorDetails.response.data.message;
  const errorMessage = TradeInfoError && TradeInfoError.data && TradeInfoError.data.message;
  const history = useHistory()
  const { register, errors, triggerValidation, handleSubmit, formState, reset, setValue} = useForm({ mode: 'onChange' })
  const { isValid } = formState
 // const disableViewDetails = (StoreAgentRole === "Store" || StoreAgentRole === 'Store Manager') ? (workingAs ? false : true) : false

  const onSubmit = (data) => {
    console.log(data)
  }

  const findTradeInfo = () => {
    actions.findCustomerTradeInfo(customerTradeId)
  }
  const [toggletip, setToggletip] = useState(false)

  useEffect(() => {
    if(resetCustomerTradeIdField) {
      setValue("customerTradeId", "");
      actions.resetCustomerTradeIdField(false);
    }
    
  }, [resetCustomerTradeIdField])

  return (
    <div>
      <div className="mx-30 lg:mx-50 mt-50 bg-white flex flex-row justify-between">
        <div className={`flex text-32 leading-37 font-VodafoneRgBd `} >Confirm Order</div>
        <div className="flex flex-row">
          <div className="mr-15 hidden lg:block h-50 py-3">
            <p className="text-22 leading-26 font-VodafoneRgBd">Customer Trade ID</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} id="order-search">
            <div className="mr-15 relative">
              <LabeledInputField
                id="customerTradeId"
                type="text"
                name="customerTradeId"
                label={resources.VODAFONE_CUSTOMER_TRADE_ID}
                placeholder="VODAFONE_CUSTOMER_TRADE_ID"
                className="w-250 h-50"
                onChange={(e) => actions.setCustomerTradeId(e)}
                register={register}
                errors={errors}
                schema="verification"
                hideLabel
              //value={customerTradeId}
              //defaultValue={customerTradeId}
              />
              {errorMessage === 'TradeId not valid' && TradeNotFound ? <div className="error-text">{resources.VODAFONE_INVALID_TRAE_ID}</div> : null}

              {TradeInStatus && TradeInStatus.toUpperCase() === 'CANCELLED' ? <div className="error-text">{resources.VODAFONE_CANCELLED_TRADE_ID}</div> : null}

               <div onMouseOver={() =>setToggletip(true)}
                onMouseOut={() => setToggletip(false)}className="flex i-tooltip float-right">
                {toggletip ? <img data-tip data-for="tooltip1" className="logo" place="bottom" src={logo1} /> : <img data-tip data-for="tooltip1" className="logo" place="bottom" src={logo} />}
                <ReactTooltip className="search-tooltip" place="bottom" type="dark" id="tooltip1" clickable globalEventOff="click" arrowColor="#00B0CA">
                  <p>
                    The Trade ID number is the
                  <br />
                    Device Test Reference
                  <br />
                    Number from the app
                </p>
                </ReactTooltip>
              </div>
            </div>
          </form>
          <div className="">
            <button
              className={`${!partnerStoreName || !isValid ? 'disable-button' : 'purple-button'} btn`}
              disabled={!partnerStoreName || !isValid }
              onClick={() => findTradeInfo()}
            >
              View details
            </button>
          </div>
        </div>
      </div>
      {!TradeInfoSearched && (
        <div className="tradeinfo-height"></div>
      )}
      {TradeInStatus && TradeInStatus.toUpperCase() === 'CANCELLED'&& (
        <div className="tradeinfo-height"></div>
      )}
      {TradeInfoSearched && (TradeInStatus && TradeInStatus.toUpperCase() !== 'CANCELLED') && <OrderDetails />}
    </div>
  )
}
export default OrderSearch
