import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
//import { useForm } from 'react-hook-form'
import { useForm } from 'react-hook-form/dist/index.ie11.development'
import { store } from '../../Store'
import { useActions } from '../../Action'
import LabeledInputField, { RenderSelectField } from '../../utils/custom-fields'
import CaptureTC from './capturetc'
import MasterData from '../../dummyjson/masterdata.json'

const customerDetails = (props) => {
  const { state, dispatch } = useContext(store)
  const actions = useActions(state, dispatch)
  const {
    CustomerInfo,
    validCustomer,
    masterdata,
    resources,
    isError,
    invalidCityState,
    customerVerificationRequested,
    customerFormVailidity,
    orderType,
    cityStateResponse,
    mobileNumber,
    billingAccountNumber,
    TradeInfo,
    serviceManamgementNumber,
    resetCustomerForm,
    isBANChecked,
    isMDNChecked,
    sericeManagementNoCheck,
    customerValidationError,
    verificationSuccessMsg,
    verificationErrorMsg,
    customerResolutionFormValidity,
    Login,
  } = state
  const { StoreAgentRole, OrderTradeRoles } = props
  const agentRole = StoreAgentRole && StoreAgentRole.toUpperCase()
  const [accountError, setAccountError] = useState(null)
  const { ProceedTradeIn, ProceedTradeUp, RemainingTerm, TradeFulFillmentType } = CustomerInfo
  const { TradeInStatus, MDN, BAN } = TradeInfo
  const orderTypeValue = orderType && orderType.value
  const updatedCustomerInfo = CustomerInfo && CustomerInfo.CustomerUpdatedInfo
  const processTrade = orderType === 'Trade In' ? ProceedTradeIn : ProceedTradeUp
  const disabledForm =
    (!validCustomer && customerVerificationRequested) || processTrade === false || (TradeInStatus && TradeInStatus.toUpperCase() !== 'OPEN' && TradeInStatus.toUpperCase() !== 'CANCELLED')
  const disableSearchFields = customerVerificationRequested ? (validCustomer ? true : false) : false
  const disableMdnBanFields = true
  const cityStateCountryData = cityStateResponse || []
  const result =
    cityStateCountryData &&
    cityStateCountryData.reduce(
      (agr, item) => {
        item.City && !agr.Cities.includes(item.City) && agr.Cities.push(item.City)
        item.State && !agr.States.includes(item.State) && agr.States.push(item.State)
        !agr.Country ? (agr.Country = item.Country) : ''
        return agr
      },
      { Cities: [], States: [], Country: '' }
    )
  let CitiesOptions = []
  let State = result && result.States[0]
  if (updatedCustomerInfo && updatedCustomerInfo.stateName === undefined && State !== undefined) {
    actions.setSelectedState(State)
  }
  if (updatedCustomerInfo && updatedCustomerInfo.cityName === '' && cityStateCountryData && cityStateCountryData.length === 1) {
    if (!invalidCityState) {
      const cityNme = result && result.Cities && result.Cities[0]
      actions.setCityName(cityNme)
    }
  }
  const Country = [{ key: 'Australia', value: 'Australia' }]
  let newStatesOptions = []
  if (!resetCustomerForm) {
    newStatesOptions =
      result &&
      result.States &&
      result.States.map((state) => {
        return {
          key: state,
          value: state,
        }
      })

    CitiesOptions = result && result.Cities && result.Cities.map((city) => ({ key: city, value: city }))
  }

  const { register, errors, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      mobileNumber: MDN,
      billingAccountNumber: BAN,
      firstName: updatedCustomerInfo && updatedCustomerInfo.firstName,
      lastName: updatedCustomerInfo && updatedCustomerInfo.lastName,
      emailAddress: updatedCustomerInfo && updatedCustomerInfo.emailAddress,
      addressLine1: updatedCustomerInfo && updatedCustomerInfo.addressLine1,
      addressLine2: updatedCustomerInfo && updatedCustomerInfo.addressLine2,
      addressLine3: updatedCustomerInfo && updatedCustomerInfo.addressLine3,
      cityName: updatedCustomerInfo && updatedCustomerInfo.cityName,
      state: updatedCustomerInfo && updatedCustomerInfo.state,
      postalCode: updatedCustomerInfo && updatedCustomerInfo.postalCode,
    },
  })

  const { isValid } = formState
  const errorLength = Object.keys(errors).length
  const formVailidity =
    updatedCustomerInfo &&
    updatedCustomerInfo.addressLine1 &&
    updatedCustomerInfo.postalCode &&
    updatedCustomerInfo.cityName &&
    updatedCustomerInfo.stateName &&
    updatedCustomerInfo.firstName &&
    updatedCustomerInfo.lastName &&
    updatedCustomerInfo.emailAddress &&
    isMDNChecked &&
    isBANChecked &&
    errorLength === 0
      ? true
      : false

  if (customerResolutionFormValidity !== formVailidity) {
    actions.setCustomerResolutionFormValidity(formVailidity)
  }

  const fulfillmentType = 'Trade-In'
  const validateInfoObj = {
    OCSGTradeRequest: {
      TradeInfoId: TradeInfo && TradeInfo.TradeInfoId,
      MSISDN: mobileNumber || '',
      BAN: billingAccountNumber || '',
      TradeFulFillmentType: fulfillmentType || '',
      // ServiceOrderId: serviceManamgementNumber || '',
      // StoreId: (Login && Login.PartnerStoreId) || '',
      // StoreAgent: Login && Login.vodafoneAgentId,
    },
  }
  const validateInfo = () => {
    const { BAN: customerBan, MSISDN } = validateInfoObj?.OCSGTradeRequest
    const customerMdn = MDN.startsWith('614') ? MDN.slice(3) : MDN.slice(2);
    const enteredMdn = MSISDN.startsWith('614') ? MSISDN.slice(3) : MSISDN.slice(2);
    if (customerBan === BAN && customerMdn === enteredMdn) {
      setAccountError(null)
      actions.validateCustomerInfoV4(validateInfoObj)
    } else {
      setAccountError('Account details entered are incorrect.')
    }
  }
  const onSubmit = (data) => {
    console.log(data)
  }

  useEffect(() => {
    if (validCustomer && TradeFulFillmentType) {
      actions.setOrderType({ value: TradeFulFillmentType === 'Trade-In' ? 'Trade In' : 'Trade Up' })
      if (TradeFulFillmentType === 'Trade-In') {
        actions.setTwelveMonthCredits('true')
      }
    }
  }, [validCustomer, TradeFulFillmentType])
  // const onChangePostalCode = (postalCode) => {
  //   console.log("resolution")
  //   if (postalCode && postalCode.length == 4 && postalCode.match(/^[0-9]+$/i) != null) {
  //     actions.setPostalCode(postalCode)
  //   }
  // }

  useEffect(() => {
    if (MDN) {
      actions.setMobileNumber(MDN)
    }
    if (BAN) {
      actions.setBillingAccountNumber(BAN)
    }
  }, [])

  const numberOnly = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault()
    }
  }

  const validButton =
    orderType === 'Trade Up' ? (errorLength === 0 && isMDNChecked && isBANChecked && isValid ? false : true) : errorLength === 0 && isMDNChecked && isBANChecked && isValid ? false : true
  const tradeInFlag = orderType === 'Trade In' ? !isMDNChecked || !isBANChecked : !isMDNChecked || !isBANChecked
  const _validButton = validCustomer === undefined || errorLength !== 0 || tradeInFlag ? validButton : validCustomer
  const errorDescription = customerValidationError ? (customerValidationError.apiFailError ? customerValidationError.apiFailError.responseText : customerValidationError.message) : undefined
  const errorDescp = customerValidationError ? errorDescription : resources.VODAFONE_VERIFICATION_FAILURE
  return (
    <div>
      <div className="Customer_details mb-20">
        <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <div className="flex flex-row">
            <div className="flex flex-col mb-30">
              <span className="text-22 font-VodafoneRgBd leading-26">Customer Details</span>
            </div>
            {accountError && (
              <div className="flex flex-col ml-5">
                <p className="validate_failure">{accountError}</p>
              </div>
            )}
            {verificationSuccessMsg && (
              <div className="flex flex-col ml-5">
                <p className={`${processTrade === true ? 'validate_success' : 'validate_failure'}`}>
                  {processTrade === true
                    ? resources.VODAFONE_LBL_VERIFICATION_SUCCESS
                    : orderType === 'Trade In'
                    ? resources.VODAFONE_LBL_CUSTOMER_TRADE_IN_NOT_ELIGIBLE
                    : resources.VODAFONE_LBL_CUSTOMER_TRADE_UP_NOT_ELIGIBLE}
                </p>
              </div>
            )}
            {verificationErrorMsg && (
              <div className="flex flex-col ml-5">
                <p className="validate_failure">{errorDescp}</p>
              </div>
            )}
          </div>

          <div className="flex flex-row ">
            <div className="flex flex-col mr-5">
              <label className="Checkbox-Container ml-3">
                <input type="checkbox" id="mobile_no" name="mobile_no" onChange={(e) => actions.setMobileNumberChecked(e.target.checked)} />
                <span className={`Checkbox-Mark ${disableSearchFields ? ' opacity-25 cursor-not-allowed' : ''}`} />
              </label>
              <span className="font-VodafoneRgBd text-base leading-19 ml-37  w-130 h-6">
                {' '}
                {resources.VODAFONE_LBL_MOBILE_NUMBER}
                <span className="text-red-1 ml-1">*</span>
              </span>
              <LabeledInputField
                type="text"
                name="mobileNumber"
                label=""
                disabled={disableMdnBanFields}
                placeholder="VODAFONE_LBL_MOBILE_NUMBER_PLACHOLDER"
                className={`${disableMdnBanFields ? ' opacity-25 cursor-not-allowed' : ''} input_box mt-2`}
                onChange={actions.setMobileNumber}
                register={register}
                errors={errors}
                schema="customerInfo"
                hideLabel
                onKeyPress={(evt) => {
                  numberOnly(evt)
                }}
                restrictInput="true"
              />
            </div>
            <div className="flex flex-col mr-5">
              <label className="Checkbox-Container text-base leading-19 ml-3">
                <input type="checkbox" id="billing" name="billing" onChange={(e) => actions.setBillingNumberChecked(e.target.checked)} />
                <span className={`Checkbox-Mark ${disableSearchFields ? ' opacity-25 cursor-not-allowed' : ''}`} />
              </label>
              <span className="font-VodafoneRgBd text-base leading-19 ml-37 w-180 h-6">
                {resources.VODAFONE_LBL_BILLING_ACCOUNT_NUMBER}
                <span className="text-red-1 ml-1">*</span>
              </span>

              <LabeledInputField
                type="text"
                name="billingAccountNumber"
                label=""
                disabled={disableMdnBanFields}
                placeholder="VODAFONE_LBL_BILLING_ACCOUNT_NUMBER_PLACEHOLDER"
                className={`${disableMdnBanFields ? ' opacity-25 cursor-not-allowed' : ''} input_box mt-2`}
                onChange={actions.setBillingAccountNumber}
                register={register}
                errors={errors}
                schema="customerInfo"
                hideLabel
              />
            </div>
            {/* {orderType === 'Trade Up' && (
              <div className="flex flex-col mr-5">
                <label className="Checkbox-Container ml-3">
                  <input type="checkbox" id="servie_management_no" name="servie_management_no" onChange={(e) => actions.setServiceManagementNoChecked(e.target.checked)} />
                  <span className={`Checkbox-Mark ${disableSearchFields ? ' opacity-25 cursor-not-allowed' : ''}`} />
                </label>
                <span className="font-VodafoneRgBd text-base leading-19 ml-37 w-130 h-6">
                  {resources.VODAFONE_LBL_SERVICE_MNGMNT_NUMBER}
                  <span className="text-red-1 ml-1">*</span>
                </span>
                <LabeledInputField
                  type="text"
                  name="serviceManagementNumber"
                  label=""
                  disabled={disableSearchFields}
                  placeholder="VODAFONE_LBL_SERVICE_MNGMNT_NUMBER_PLACHOLDER"
                  className={`${disableSearchFields ? ' opacity-25 cursor-not-allowed' : ''} input_box_customerdetail mt-2`}
                  onChange={actions.setServiceManagementNumber}
                  register={register}
                  errors={errors}
                  schema="customerInfo"
                  hideLabel
                />
              </div>
            )} */}
            <div className="flex flex-col mt-26">
              <button id="validateBtn" disabled={_validButton} onClick={() => validateInfo()} className={`${_validButton ? 'disable-button' : 'purple-button'} validateBtn `} type="button">
                {resources.VODAFONE_BTN_VALIDATE}
              </button>
            </div>
          </div>

          {customerVerificationRequested && (
            <div>
              <div className="flex flex-row mt-30">
                <div className="flex flex-col mr-5">
                  <LabeledInputField
                    type="text"
                    name="firstName"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    //value={updatedCustomerInfo && updatedCustomerInfo.firstName}
                    label="VODAFONE_LBL_FIRST_NAME"
                    placeholder="VODAFONE_FIRSTNAME_PLACEHOLDER"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setFirstName}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                    Mandatory
                  />
                </div>
                <div className="flex flex-col">
                  <LabeledInputField
                    type="text"
                    name="lastName"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    //value={updatedCustomerInfo && updatedCustomerInfo.lastName}
                    label="VODAFONE_LBL_LAST_NAME"
                    placeholder="VODAFONE_LASTNAME_PLACEHOLDER"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setLastName}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                    Mandatory
                  />
                </div>
                <div className="flex flex-col ml-5">
                  <LabeledInputField
                    type="text"
                    name="emailAddress"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    // value={updatedCustomerInfo && updatedCustomerInfo.emailAddress}
                    label="VODAFONE_LBL_EMAIL"
                    placeholder="VODAFONE_EMAILADD_PLACEHOLDER"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setEmailAddress}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                    Mandatory
                  />
                </div>
              </div>

              <div className="flex flex-row mt-30">
                <div className="flex flex-col">
                  <LabeledInputField
                    type="text"
                    name="addressLine1"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    //value={updatedCustomerInfo && updatedCustomerInfo.addressLine1}
                    label="VODAFONE_LBL_ADD_LINE_01"
                    placeholder="VODAFONE_LBL_ADD_LINE_01"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setAddressLine1}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                    Mandatory
                  />
                </div>
                <div className="flex flex-col ml-5">
                  <LabeledInputField
                    type="text"
                    name="addressLine2"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    //value={updatedCustomerInfo && updatedCustomerInfo.addressLine2}
                    label="VODAFONE_LBL_ADD_LINE_02"
                    placeholder="VODAFONE_LBL_ADD_LINE_02"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setAddressLine2}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                  />
                </div>
                <div className="flex flex-col ml-5">
                  <LabeledInputField
                    type="text"
                    name="addressLine3"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    //value={updatedCustomerInfo && updatedCustomerInfo.addressLine3}
                    label="VODAFONE_LBL_ADD_LINE_03"
                    placeholder="VODAFONE_LBL_ADD_LINE_03"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={actions.setAddressLine3}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                  />
                </div>
              </div>

              <div className="flex flex-row mt-30">
                <div className="flex flex-col">
                  <LabeledInputField
                    type="text"
                    name="postalCode"
                    labelClass="ml-3"
                    disabled={disabledForm}
                    // value={updatedCustomerInfo && updatedCustomerInfo.postalCode}
                    label="VODAFONE_LBL_POSTAL_CODE"
                    placeholder="VODAFONE_LBL_POSTAL_CODE"
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white' : 'bg-white'}`}
                    onChange={(e) => props.zipChnge(e)}
                    register={register}
                    errors={errors}
                    schema="customerInfo"
                    Mandatory
                  />
                  {invalidCityState && !resetCustomerForm ? <div className="error-text">{resources.VODAFONE_LBL_INVALID_POSTCODE}</div> : null}
                </div>
                <div className="flex flex-col ml-5">
                  {cityStateCountryData && cityStateCountryData.length > 1 ? (
                    <RenderSelectField
                      name="city"
                      label="VODAFONE_LBL_CITYNAME"
                      labelClass="ml-3"
                      // value={invalidCityState ? " " : updatedCustomerInfo && updatedCustomerInfo.cityName}
                      options={CitiesOptions}
                      optionsKey="key"
                      optionsValue="value"
                      register={register}
                      errors={errors}
                      onChange={(e) => actions.setCityName(e)}
                      schema="customerinfo"
                      className={`mt-2 ${invalidCityState || disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white disabled-select' : 'bg-white'}`}
                      disabled={invalidCityState || (!validCustomer && customerVerificationRequested)}
                      required={true}
                      Mandatory
                    />
                  ) : (
                    <div className="flex flex-col">
                      <LabeledInputField
                        type="text"
                        name="city"
                        labelClass="ml-3"
                        disabled={disabledForm}
                        value={updatedCustomerInfo && updatedCustomerInfo.cityName}
                        label="VODAFONE_LBL_CITYNAME"
                        placeholder="VODAFONE_LBL_CITYNAME"
                        className={`${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white disabled-select mt-2' : 'mt-2 bg-white'}`}
                        onChange={(e) => actions.setCityName(e)}
                        register={register}
                        errors={errors}
                        schema="customerInfo"
                        Mandatory
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col ml-5">
                  <RenderSelectField
                    name="state"
                    disabled={disabledForm}
                    labelClass="ml-3"
                    label="VODAFONE_LBL_STATE"
                    //value={invalidCityState ? " " : State}
                    Mandatory
                    className={`mt-2 ${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white disabled-select' : 'bg-white'}`}
                    register={register}
                    onChange={(e) => actions.setSelectedState(e)}
                    options={newStatesOptions}
                    optionsLabel="key"
                    optionsValue="value"
                    errors={errors}
                    schema="customerInfo"
                  />
                </div>
              </div>
              <div className="flex flex-row mt-30">
                <div className="flex flex-col ">
                  <RenderSelectField
                    name="country"
                    disabled={disabledForm /* (invalidCityState || (!validCustomer&& customerVerificationRequested))? true :false */}
                    labelClass="ml-3"
                    label="VODAFONE_LBL_COUNTRY"
                    value={updatedCustomerInfo && updatedCustomerInfo.postalCode !== undefined && !invalidCityState ? Country[0].key : ' '}
                    className={`${disabledForm ? 'cursor-not-allowed bg-gray-2 placeholder-white disabled-select' : 'mt-2 bg-white'}`}
                    register={register}
                    onChange={(e) => actions.setSelectedCountry(e)}
                    options={Country}
                    optionsLabel="key"
                    optionsValue="value"
                    Mandatory
                    errors={errors}
                    schema="customerInfo"
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
export default customerDetails
